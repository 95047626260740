<template>
  <div class="home">

    <el-page-header @back="goBack" title="退出" :content="name">
    </el-page-header>
    <br>



    <br>

    <div>

      <el-button-group>
        <el-button :type="map.mode == 1 ? 'primary' : ''" @click="set_mode(1)">原图</el-button>
        <el-button :type="map.mode == 2 ? 'primary' : ''" @click="set_mode(2)">精修</el-button>

      </el-button-group>
      |
      <el-button-group>
        <el-button :type="map.has_order == 0 ? 'primary' : ''" @click="map.has_order = 0">异常照片</el-button>
        <el-button :type="map.has_order == 1 ? 'primary' : ''" @click="map.has_order = 1">正常照片</el-button></el-button>

      </el-button-group>
      |
      <el-select v-model="map.staff_id" placeholder="摄影师" filterable>
        <el-option v-for="item in staff" :key="item.id" :label="item.id + ' ' + item.tel + ' ' + item.name"
          :value="item.id">
        </el-option>
      </el-select>

      <el-date-picker v-model="map.date" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期">
      </el-date-picker>

      <el-button icon="el-icon-search" circle @click="do_find"></el-button>
      <el-button @click="edit_batch"> 批量修改</el-button>

    </div>
    <br>
    <div class="shot_log">
      <el-tabs v-model="activeName">
        <el-tab-pane label="拍摄记录" name="first">
          <div v-for="(vo, i) in shot_log" :key="i">
            {{vo.id}}:{{ hi(vo.start_time) }}- {{ hi(vo.end_time) }} / {{ vo.order_id }}/ {{ vo.ord.sn }}
          </div>
        </el-tab-pane>
        <el-tab-pane label="异常订单" name="second">
          <div v-for="(vo, i) in no_order" :key="i">
            {{ vo.sn }} | {{ vo.tel || vo.user.tel || '' }}| 已有照片 {{ vo.oss_count }}
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="fxz">

      <div class="list">
        <div class="itm" v-for="(vo, i) in list" :key="i">
          <img :src="map.mode == 1 ? vo.res_urlx + '?x-oss-process=300sm' : vo.org_urlx + '?x-oss-process=300sm'" alt=""
            @click="show_one(i)">
          <input type="checkbox" v-model="vo.is_select" />

          <div>
            {{ vo.shot_time }}
          </div>
          <div v-if="vo.order_id > 0 && vo.ord &&  vo.ord.id">
            {{ vo.ord.sn }}
          </div>
          <div v-else>
            无拍摄参数
          </div>

        </div>
      </div>
    </div>



    <el-dialog title="大图" :visible.sync="dialogVisible" width="80%">
      <div class="tc" v-if="dialogVisible">
        <div class="big">
          <img :src="map.mode == 1 ? row.res_urlx + '?x-oss-process=300sm' : row.org_urlx + '?x-oss-process=300sm'"
            alt="">

          <div>
            {{ row.shot_time }}
          </div>
        </div>
        <div class="right">
          <div v-if="near.pre">
            <div>
              上一张
            </div>
            <img :src="near.pre.res_urlx + '?x-oss-process=300sm'" alt="">
            <div>
              {{ near.pre.shot_time }}
            </div>
            <div>
              {{ near.pre.ord.id }} | {{ near.pre.ord.sn }} | {{ near.pre.ord.tel }}
            </div>

          </div>
          <div v-if="near.last">
            <div>
              下一张
            </div>
            <img :src="near.last.res_urlx + '?x-oss-process=300sm'" alt="">
            <div>
              {{ near.last.shot_time }}
            </div>
            <div>
              {{ near.last.ord.id }} | {{ near.last.ord.sn }} | {{ near.last.ord.tel }}
            </div>

          </div>
        </div>



      </div>
    </el-dialog>


    <el-dialog title="批量修改" :visible.sync="edit_show" width="80%">
      
        <el-input v-model="edit.order_id" placeholder="订单ID"></el-input>
        <br>

        <el-input v-model="edit.sn" placeholder="订单编号"></el-input>


      <br>
        <el-button @click="do_edit_batch"> 提交</el-button>
    </el-dialog>


    <el-dialog v-if="0" title="批量替换" :visible.sync="ba_cov" width="80%">
      <div class="cover">

        <div>
          <input type="file" ref="haoduowenjian" @change="files_change" multiple />
          <el-button @click="haoduowenjianshangchuan">提交</el-button>
        </div>
        <!-- <div class="rlin" v-for="(vo, i) in filess" :key="i"  >
            <div class="left">
              <img :src="vo.src">
            </div>
            <div class="right">
              {{ vo.name }}
            </div>
          </div> -->
        <div class="rlin" v-for="(vo, i) in f2p" :key="i">
          <div class="left">
            <img :src="vo.truing_s">
            <div>
              {{ base_name(vo.img_name) }}
            </div>
          </div>
          <div class="right">

            <div v-if="vo.up_name">
              <img :src="filess[vo.up_name]" alt="">
            </div>
            <div>
              {{ vo.up_name }}
            </div>
          </div>
        </div>
      </div>
    </el-dialog>







    <hr>
    <div @click="f5">加载更多</div>


  </div>
</template>

<script>

const OSS = require('ali-oss');
const client = new OSS({
  // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
  region: 'oss-cn-beijing',
  // 从环境变量中获取访问凭证。运行本代码示例之前，请确保已设置环境变量OSS_ACCESS_KEY_ID和OSS_ACCESS_KEY_SECRET。
  accessKeyId: 'LTAI5t6AwavozvtddTBQZ6pP',
  accessKeySecret: 'mdqM4nuikHAPcA10jNSIVwlHKsubTt',
  // 填写Bucket名称。例如examplebucket。
  bucket: 'chaoyunphoto',
  // 设置是否启用HTTPS。设置secure为true时，表示启用。
  // secure: true
})

async function isExistObject(name, options = {}) {
  try {
    await client.head(name, options);
    console.log('文件存在')
  } catch (error) {
    if (error.code === 'NoSuchKey') {
      console.log('文件不存在')
    }
  }
}



// copySmallObjecInSameBucket()
// isExistObject('audit/partner/达咔北京/圆明园店/发送订单/230929/正常/2412740-1/01L-/IMG_7072-01L1.jpg')
export default {
  name: 'HomeView',
  components: {

  },
  data() {
    return {
      list: [],
      listLoading: true,
      i: -1,
      id: -1,
      row: {},
      dialogVisible: false,
      ba_cov: false,
      total: 0,
      per_page: 0,
      type: 1,
      name: '',
      staff: [],
      shot_log: [],
      near: {},
      no_order: [],
      activeName: 'first',
      map: {
        has_order : 0,
        staff_id: 300,
        ppdsize: 50,
        rmd: 1,
        mode: 2,
        date: '2023-10-02',
        page: 1,
      },
      show: {
        card_name: '',
        number: '',
        exp: '',
        cvv: '',
        bank: '',
      },
      edit : {
        order_id : '',
        sn : '',
      },
      edit_show:false,
      ws: null,
      ws_live: true,
      info: { id: 0, },
      ft_time: null,
      ffile: '',
      filess: [],
      f2p: {},
      name2file: {},
    }
  },
  created() {
    // if (!localStorage.getItem('token')) {
    //   this.$router.replace({ path: '/login' })
    // } else {

    //   // this.f5()
    // }
    this.f5()
    this.filter()

  },
  beforeDestroy() {
    this.ws_live = false
  },
  methods: {
    async deleteObject() {
      try {
        // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
        let result = await client.delete(this.row.img_name);
        console.log(result);
        this.$message({
          'message': '删除成功:' + this.row.img_name,
        })
      } catch (error) {
        console.log(error);
      }
    },
    async copySmallObjecInSameBucket() {

      try {
        // 填写拷贝后和拷贝前的文件完整路径。文件完整路径中不能包含Bucket名称。
        // 设置目标文件的HTTP头和自定义目标文件的元信息。
        const result = await client.copy(this.rename(this.row.img_name),
          this.row.img_name);
        console.log(result);
        this.$message({
          'message': '备份成功:' + this.row.img_name,
        })
      } catch (e) {
        console.log(e);
      }
    },

    async put() {
      try {
        // 填写OSS文件完整路径和本地文件的完整路径。OSS文件完整路径中不能包含Bucket名称。
        // 如果本地文件的完整路径中未指定本地路径，则默认从示例程序所属项目对应本地路径中上传文件。
        const result = await client.put(this.row.img_name, this.ffile);
        console.log(result);
        this.dialogVisible = false
        this.$message({
          'message': '替换成功:' + this.row.img_name,
        })
      } catch (e) {
        console.log(e);
      }
    },
    async haoduowenjianshangchuan() {
      for (const vo of this.f2p) {
        if (vo.up_name) {
          this.row = vo
          this.ffile = this.name2file[vo.up_name]
          await this.copySmallObjecInSameBucket()
          await this.deleteObject()
          await this.put()
        }
      }
      this.ba_cov = false
    },
    batch_cover() {


      this.f2p = []
      for (const vo of this.list) {
        vo.up_name = ''
        this.f2p.push(vo)
      }
      console.log(this.f2p)
      this.ba_cov = true
    },
    files_change() {
      let ths = this
      const files = this.$refs.haoduowenjian.files;
      ths.filess = {}
      ths.name2file = {}

      for (const vo of files) {
        const reader = new FileReader();
        reader.readAsDataURL(vo);
        reader.onload = e => {

          ths.name2file[vo.name] = vo
          ths.filess[vo.name] = e.target.result
          let name = vo.name.replace('.jpg', '')
          for (const rr of ths.f2p) {
            if (rr.img_name.indexOf(name) !== -1) {
              rr.up_name = vo.name

              break
            }
          }
          this.$forceUpdate()

        };

      }

    },
    async cover() {



      // this.deleteObject()

      const files = this.$refs.file.files;

      if (files && files[0]) {

        // 获取原文件名
        const fileName = files[0].name;

        console.log(fileName);


        console.log(files[0])
        this.ffile = files[0]
        await this.copySmallObjecInSameBucket()
        await this.deleteObject()
        await this.put()
      }

    },
    batch_down() {

      for (const vo of this.list) {
        if (vo.is_select) {
          window.open(vo.cr2, '__bank', 'noopener')
        }
      }
    },
    edit_batch(){
      this.edit_show = true
    },
    do_edit_batch(){
      let ids = []
      for (const vo of this.list) {
        if (vo.is_select) {
          ids.push(vo.id)
        }
      }
      if (ids.length == 0) {
        return this.$message({
          message : '先选择照片',
        })
      }
      if (!this.edit.order_id || this.edit.sn ) {
        return this.$message({
          message : '填写订单号或者订单ID',
        })
      }
      this.get({
        url: `/oss/edit`,
        method: 'post',
        data : {
          ids : ids,
          ...this.edit
        }

      }).then(e => {
        this.$message({
          message:'成功',
        })
        this.edit_show = false
         this.do_find()


      })
    },
    do_find() {
      this.list = []
      this.map.rmd = this.map.mode
      this.map.page = 1
      this.f5()
    },
    set_mode(n) {
      this.map.mode = n
      this.$forceUpdate()
    },
    show_one(i) {
      this.i = i
      this.row = this.list[i]
      this.get({
        url: `/oss/near/${this.map.staff_id}/${this.row.id}`,
        method: 'get',

      }).then(e => {
        this.near = e.data


      })
      this.dialogVisible = true
    },
    goBack() {
      localStorage.setItem('token', '')
      this.$router.replace({ path: '/login' })
    },

    filter() {
      this.get({
        url: '/oss/filter',
        method: 'get',

      }).then(e => {
        this.staff = e.data.staff


      })
    },
    f5() {

      this.get({
        url: '/oss/list',
        method: 'post',
        data: this.map
      }).then(e => {

        this.listLoading = false;
        this.list = e.data.list
        this.shot_log = e.data.shot_log
        this.no_order = e.data.no_order
      })
    },


  }
}
</script>


<style  scoped lang="scss"> 

.shot_log {
   position: fixed;
   z-index: 2;
   right: 0;
   top: 100px;
   background: #eee;
   width: 19vw;
   min-height: 50vh;
 }

 .list {
   display: flex;
   justify-content: space-between;
   width: 80vw;
   flex-wrap: wrap;

   .itm {
     flex: 0 0 19%;

     img {
       width: 100%;
       height: auto;
     }
   }
 }

 .tc {
   display: flex;

   img {
     width: 50%;
     height: auto;
   }

   .big {
     flex: 0 0 60%;
   }

   .right {
     flex: 0 0 40%;
   }
 }

 .cover {
   .rlin {
     display: flex;
     justify-content: space-between;
     border-bottom: 1px solid #000;

     img {
       width: 150px;
     }
   }
 }
</style> 